<template>
  <div id="SpecieRecipesCategory" :key="viewKey()" class="container max-w-4xl mx-auto pt-10 pb-20 px6">

    <div class="flex flex-wrap mt-4 mb-6 mx-4 md:mx-0">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
    </div>

    <RecipeListForSpecieTag v-if="!loading && tag.tags.length == 0" :specie="specie" :tag="tag" :key="specieTagRecipeListKey()" />
    <SubtagListForSpecieTag v-if="!loading && tag.tags.length > 0" :specie="specie" :tag="tag" :key="specieTagSubtagListKey()" />

  </div>
</template>

<script setup>

  import { onMounted, ref, watch } from 'vue'
  import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
  import TagService from '../../services/tag.service'
  import SpecieService from '../../services/specie.service'
  import { useToast } from 'vue-toastification'
  import RecipeListForSpecieTag from '@/components/partials/recipe/RecipeListForSpecieTag.vue'
  import SubtagListForSpecieTag from '@/components/partials/recipe/SubtagListForSpecieTag.vue'

  const router = useRouter()
  const route = useRoute()
  const toast = useToast()

  let loading = ref(true)
  let specieSlug = ref(null)
  let tagSlug = ref(null)
  let tag = ref({name : {en: '...'}, slug: { en: 'tag-loading' }})
  let specie = ref({slug: 'loading-specie'})

  // Watch for changes in route
  // watch(() => route.params, (newParams, oldParams) => {
  //   if (newParams.slug !== oldParams.slug || newParams.tag !== oldParams.tag) {
  //     init()
  //   }
  // }, { deep: true })

  // this is a bit confusing if u don't know how it works... what this does is call the watch function which
  // in this context is setup to watch for changes in the params and invoke init()when that happens, but the
  // watch function actually returns a cleanup function that when calls then destroys the watcher that was
  // initiallyl created. That is why we set the return value to a var named "stopParamsWatch" which can then
  // be called as a function onBeforeRouteLeave so that the watcher doesn't keep watching when this route
  // is navigated away from
  const stopParamsWatch = watch(
    () => route.params,
    (newParams, oldParams) => {
      if (newParams.slug !== oldParams.slug || newParams.tag !== oldParams.tag) {
        init();
      }
    },
    { deep: true }
  );

  onBeforeRouteLeave((to, from, next) => {
    // Clean up the watcher before leaving the route
    stopParamsWatch();
    next();
  });

  onMounted(async () => {
    init()
  })

  const init = async () => {
    specieSlug.value = route.params.slug
    tagSlug.value = route.params.tag
    await Promise.all([getTag(), getSpecie()])
    loading.value = false
  }

  const getTag = async () => {
    try {
      let res = await TagService.getSpecieRecipeTag(specieSlug.value, tagSlug.value)
      tag.value = res.data.tag
      console.log('tag', res.data.tag)
    } catch (err) {
      toast.error('Failed to get tag')
    }
  }

  const getSpecie = async () => {
    try {
      let res = await SpecieService.getSpecie(specieSlug.value)
      specie.value = res.data.specie
    } catch (err) {
      toast.error('Failed to get specie')
    }
  }

  const specieTagRecipeListKey = async () => {
    return specie.value.tag + '-' + tag.value.slug.en + 'recipe-list'
  }

  const specieTagSubtagListKey = async () => {
    return specie.value.tag + '-' + tag.value.slug.en + 'subtag-list'
  }

  const viewKey = () => {
    let key = 'specie-' + specie.value.slug + '-category-' + tag.value.slug.en
    console.log(key)
    return key
  }

  const goBack = () => {
    router.go(-1)
  }
</script>
