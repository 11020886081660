import Api from '../apis/api'

class NutrietService {
  getNutrients () {
    return Api.get('api/admin/nutrients')
  }

  updateNutrient (nutrientId, data) {
    return Api.post('api/admin/nutrients/' + nutrientId + '?_method=PUT', data)
  }
}

export default new NutrietService()
