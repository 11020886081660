<template>
    <div class="container max-w-4xl mx-auto pt-20 pb-20 px6">
        <div class="grid place-items-center">
            <div class="p-4 w-11/12 md:w-2/3">
                <RouterLink class="block
                        w-full
                        bg-innoorange
                        hover:bg-innoorange-hover
                        text-white
                        rounded-sm
                        py-3
                        text-sm
                        tracking-wide
                        text-center" to="suggested-recipes">Chef Paw Suggested Recipes</RouterLink>
                <ListRecipesSimple :recipes="suggestedRecipes"></ListRecipesSimple>
            </div>

        </div>
        <div class="grid place-items-center">
            <div class="p-4 w-11/12 md:w-2/3">
                <RouterLink class="block
                        w-full
                        bg-innoorange
                        hover:bg-innoorange-hover
                        text-white
                        rounded-sm
                        py-3
                        text-sm
                        tracking-wide
                        text-center" to="inspired-recipes">Comunity Inspired Recipes</RouterLink>
                <ListRecipesSimple :recipes="inspiredRecipes"></ListRecipesSimple>
            </div>
        </div>

    </div>
</template>
  
<script setup>

import { onMounted, ref } from 'vue';
import recipeService from '@/services/recipe.service';
import ListRecipesSimple from '@/components/partials/recipe/ListRecipesSimple.vue';

let suggestedRecipes = ref([]);
let inspiredRecipes = ref([]);
let searching = ref(false);
let errors = ref([]);

onMounted(async () => {
    getRecipes()
})

const getRecipes = async () => {
    searching.value = true
    try {
        let [inspiredResponse, suggestedResponse] = await Promise.all([recipeService.listInspiredRecipes(1, 3), recipeService.listSuggestedRecipes(1, 3)])
        inspiredRecipes.value = inspiredResponse.data.recipes
        suggestedRecipes.value = suggestedResponse.data.recipes
        console.log(inspiredResponse.data.recipes)
        searching.value = false
    } catch (err) {
        errors.value = err.response.data.errors
        searching.value = false
    }
}
</script>
