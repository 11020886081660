<template>
    <ListRecipes type="saved-recipes">
      <template v-slot:btnGoToLibraryRecipes>
        <button class="
          mb-3
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="gotoRecipeLibaryPage">
          Recipe Library
        </button>
      </template>
    </ListRecipes>
</template>

<script setup>

    import ListRecipes from './ListRecipes.vue';
    import { useRouter } from 'vue-router' 

    const router = useRouter();

    const gotoRecipeLibaryPage = () => {
        router.push({ name: 'RecipesLibrary' });
    }
</script>
