import Api from '../apis/api'

class RecipeStepTemplateGroupService {

  listRecipeStepTemplateGroups () {
    return Api.get('api/admin/recipe-step-template-groups')
  }

  getRecipeStepTemplateGroup (id) {
    return Api.get('api/admin/recipe-step-template-groups/' + id)
  }

  updateRecipeStepTemplateGroup (id, data) {
    console.log('updateRecipeStepTemplateGroup', data)
    return Api.post('api/admin/recipe-step-template-groups/' + id + '?_method=PUT', data)
  }
}

export default new RecipeStepTemplateGroupService()
