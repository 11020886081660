<template>
    <div>
        <div v-if="recipes.length > 0">
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Recipe name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(recipe, index) in recipes" :key="recipe.id"
                            :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                <a href="javascript:void(0)" @click="goToShowRecipe(recipe)">{{ recipe.name }}</a>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script setup>
import { toRefs, defineProps } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
    recipes: Array
});

const { recipes } = toRefs(props);

const goToShowRecipe = (recipe) => {
    router.push({ name: 'ShowRecipe', params: { id: recipe.id } })
}
</script>
