<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
      <div v-if="isVetInspired" class="basis-1/4 ml-auto">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goToVets()">
          ChefPaw Vets
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">{{ getPageNameBaseOnType(type) }}</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div v-if="recipes.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg bg-white">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mb-[200px]">
              <tbody>
                  <tr v-for="(recipe, index) in recipes" :key="recipe.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <div class="flex justify-start">
                          <a href="javascript:void(0)" @click="goToShowRecipe(recipe)" class="text-lg">{{ recipe.name }}</a>
                        </div>
                        

                        <div class="flex justify-start mt-2">
                          <div class="mx-2">
                            <button v-if="pets.length > 0" :id="recipeActionsBtnId(recipe)" :data-dropdown-toggle="recipeActionsDropdownId(recipe)" data-dropdown-placement="bottom" class="text-gray-900 bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Recipe Actions <svg class="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
                            <!-- Dropdown menu -->
                            <div v-if="pets.length > 0" :id="recipeActionsDropdownId(recipe)" class="z-10 hidden bg-white rounded-lg shadow w-60 dark:bg-gray-700">
                              <ul class="h-48 py-2 overflow-y-auto text-gray-700 dark:text-gray-200" :aria-labelledby="recipeActionsBtnId(recipe)">
                                <li>
                                  <a href="javascript:void(0)" @click="goToShowRecipe(recipe)" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">View</a>
                                </li>
                                <li v-if="canManageRecipe(recipe)">
                                  <a href="javascript:void(0)" v-if="canManageRecipe(recipe)" @click="editRecipe(recipe)" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Edit</a>
                                </li>
                                <li>
                                  <a href="javascript:void(0)" @click="goToRecipe(recipe)" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Nutrients Per Batch</a>
                                </li>
                                <li>
                                  <a href="javascript:void(0)" v-if="canManageRecipe(recipe)" @click="prepareToDuplicateRecipe(recipe)" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Duplicate</a>
                                  <a href="javascript:void(0)" v-if="!canManageRecipe(recipe)" @click="prepareToDuplicateRecipe(recipe)" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Edit</a>
                                </li>
                                <li v-if="canManageRecipe(recipe)">
                                  <a href="javascript:void(0)" @click="destroyRecipe(recipe)" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Delete</a>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class="mx-2">
                            <button v-if="pets.length > 0" :id="recipePetsBtnId(recipe)" :data-dropdown-toggle="recipePetsDropdownId(recipe)" data-dropdown-placement="bottom" class="text-gray-900 bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Calculate Nutrition <svg class="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
                            <!-- Dropdown menu -->
                            <div v-if="pets.length > 0" :id="recipePetsDropdownId(recipe)" class="z-10 hidden bg-white rounded-lg shadow w-60 dark:bg-gray-700">
                              <ul class="h-48 py-2 overflow-y-auto text-gray-700 dark:text-gray-200" :aria-labelledby="recipePetsBtnId(recipe)">
                                <li v-for="pet in pets" :key="recipePetDropdownKey(recipe, pet)">
                                  <router-link :to="{ name: 'PetRecipeNutrition', params: { petId: pet.id, recipeId: recipe.id}}" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                    <img class="w-6 h-6 mr-2 rounded-full" :src="petImgUrl(pet)" alt="Pet Image">
                                    {{ pet.name }}
                                  </router-link>
                                  <!-- <a href="#" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                    <img class="w-6 h-6 mr-2 rounded-full" :src="petImgUrl(pet)" alt="Pet Image">
                                    {{ pet.name }}
                                  </a> -->
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div v-if="false" class="mx-2">
                            <button v-if="pets.length > 0" :id="recipeAdvancedEditWithPetBtnId(recipe)" :data-dropdown-toggle="recipeAdvancedEditWithPetDropdownId(recipe)" data-dropdown-placement="bottom" class="text-gray-900 bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Advanced Editing <svg class="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
                            <!-- Dropdown menu -->
                            <div v-if="pets.length > 0" :id="recipeAdvancedEditWithPetDropdownId(recipe)" class="z-10 hidden bg-white rounded-lg shadow w-60 dark:bg-gray-700">
                              <ul class="h-48 py-2 overflow-y-auto text-gray-700 dark:text-gray-200" :aria-labelledby="recipeAdvancedEditWithPetBtnId(recipe)">
                                <li v-for="pet in pets" :key="recipeAdvancedEditWithPetDropdownKey(recipe, pet)">
                                  <router-link :to="{ name: 'RecipeAdvancedEditForPet', params: { petId: pet.id, recipeId: recipe.id}}" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                    <img class="w-6 h-6 mr-2 rounded-full" :src="petImgUrl(pet)" alt="Pet Image">
                                    {{ pet.name }}
                                  </router-link>
                                  <!-- <a href="#" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                    <img class="w-6 h-6 mr-2 rounded-full" :src="petImgUrl(pet)" alt="Pet Image">
                                    {{ pet.name }}
                                  </a> -->
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>



                      </th>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

    <div v-if="type == GetRecipeType.MY_RECIPES" class="grid place-items-center">
      <div class="p-4 w-11/12 md:w-2/3">
        <slot name="btnGoToLibraryRecipes"></slot>
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goToAddRecipe()">
          Add Recipe
        </button>
      </div>
    </div>

    <div v-if="type == GetRecipeType.SUGGESTED_RECIPES" class="container max-w-4xl mx-auto text-xs text-gray-700 mt-4">
        <div class="text-center"><strong>Disclaimer for Suggested ChefPaw Recipes:</strong></div>
        <p>
          The suggested Chefpaw recipes are general recipes based on commonly available ingredients from grocery stores in  the USA. All pet food recipes require some nutritional supplementation in order to be considered balanced to AAFCO or NRC standards. Chefpaw’s dietary supplement is included with Chefpaw suggested recipes in order to supplement only the most common nutritional deficiencies. To add other brands of supplements, simply add the supplement and its nutritional details as a new ingredient and customize recipes as needed. These suggested recipes have not been evaluated by the AAFCO, NRC or USDA, and Innovet Pet Products has no affiliation with those organizations. Innovet Pet Products cannot guarantee that Chefpaw’s suggested recipes will be adequate for the specific needs of your particular pet. Custom recipes based off of your pets unique medical history can only be obtained by a licensed veterinarian.
        </p>
    </div>

    <ModalDisplay
      v-if="showWarningModal"
      headerText="WARNING"
      headerStyles="text-innoorange"
      okButtonText="I accept all risk and want to continue to edit"
      okButtonStyles="bg-innoorange hover:bg-innoorange-hover"
      cancelButtonStyles="bg-innogreen hover:bg-innogreen-hover"
      @cancel="showWarningModal = false"
      @confirm="proceedWithDuplication"
    >
      <template #body>
        <div class="space-y-4">
          <p>This recipe was professionally customized for your dog's needs. <strong>PLEASE</strong> do not jeopardize your pet's health or quality of life by editing this recipe on your own. Many medications can become ineffective if there is too little or too much of certain nutrients.</p>
          
          <p>For your pet's sake, please contact a licensed Veterinary Nutritionist about editing a recipe or meal plan that has been customized for your pet's specific needs.</p>
          
          <p class="font-bold text-innoorange">ChefPaw recommends the following independent professionals for customizing recipes:</p>
          
          <div class="space-y-6">
            <div>
              <p class="font-bold">Jessica Fusch, DVM</p>
              <p>CVFT</p>
              <p>Farmers Market Fido</p>
              <a href="https://calendly.com/famersmarketfido" target="_blank" class="text-blue-600 hover:underline">Book Now</a>
            </div>

            <div>
              <p class="font-bold">Laura Gaylord, DVM</p>
              <p>DACVIM (Nutrition)</p>
              <p>Whole Pet Provisions</p>
              <p>Board Certified Veterinary Nutritionist</p>
              <a href="https://wholepetprovisions.com/" target="_blank" class="text-blue-600 hover:underline">https://wholepetprovisions.com/</a>
              <p>email: <a href="mailto:info@wholepetprovisions.com" class="text-blue-600 hover:underline">info@wholepetprovisions.com</a></p>
              <a href="https://wholepetprovisions.com/schedule-a-consultation/" target="_blank" class="text-blue-600 hover:underline">Book Now</a>
            </div>
          </div>

          <p class="italic">Note: CVFT's can make customized recipes for healthy pets only. Custom recipes for pets with medical conditions require highly specialized ACVIM training.</p>
          
          <p class="font-bold text-innoorange">By clicking the continue to editing button below, you are assuming 100% of the risk to your pet, 100% of all resulting liabilities, and complicating the ability for any professional to diagnose and treat your pet.</p>
        </div>
      </template>
    </ModalDisplay>

  </div>
</template>

<script setup>

  import { onMounted, ref, toRefs, computed } from 'vue'
  import { useRouter } from 'vue-router'
  import UserService from '../../services/user.service'
  import RecipeService from '../../services/recipe.service.js'
  import PetService from '../../services/pet.service.js'
  import Swal from 'sweetalert2'
  import { defineProps } from 'vue'
  import { useUserStore } from '@/store/user-store'
  import { initFlowbite } from 'flowbite'
  import ModalDisplay from '@/components/partials/modals/ModalDisplay.vue'

  const router = useRouter()
  const userStore = useUserStore()
  const GetRecipeType = {
    MY_RECIPES : 'my-recipes',
    SUGGESTED_RECIPES : 'suggested-recipes',
    INSPIRED_RECIPES : 'inspired-recipes',
    SAVED_RECIPES : 'saved-recipes',
    SHARED_WITH_ME : 'shared-with-me'
  }

  const props = defineProps({
    type: String,
  })

  const { type } = toRefs(props)

  let searching = ref(false);
  let recipes = ref([])
  let testRecipes = ref([])
  let pets = ref([])
  let errors = ref([])

  const showWarningModal = ref(false)
  const proceedWithDuplication = ref(null)

  onMounted(async () => {
    await Promise.all([getRecipes(), getPets()])
    initFlowbite()
  })

  const isVetInspired = computed({
      get: () => type.value == GetRecipeType.SHARED_WITH_ME
  })

  const getRecipesBaseOnType = (type) => {
    switch (type) {
      case GetRecipeType.INSPIRED_RECIPES:
        return RecipeService.listInspiredRecipes();
      case GetRecipeType.MY_RECIPES:
      case GetRecipeType.SAVED_RECIPES:
        return RecipeService.listMyRecipes();
      case GetRecipeType.SUGGESTED_RECIPES:
        return RecipeService.listSuggestedRecipes();
      case GetRecipeType.SHARED_WITH_ME:
        return UserService.getRecipesSharedWithMe();
      default:
        return RecipeService.listRecipes();
    }
  }

  const getPageNameBaseOnType = (type) => {
    switch (type) {
      case GetRecipeType.INSPIRED_RECIPES:
        return 'Community Inspired Recipes';
      case GetRecipeType.MY_RECIPES:
        return 'My Recipes';
      case GetRecipeType.SUGGESTED_RECIPES:
        return 'Suggested Recipes';
      case GetRecipeType.SAVED_RECIPES:
        return 'Your Saved Recipes'
      case GetRecipeType.SHARED_WITH_ME:
        return 'Veterinarian Customized Recipes'
      default:
        return 'Recipes';
    }
  }

  const getRecipes = async () => {
    searching.value = true
    try {
      let res = await getRecipesBaseOnType(type.value);
      recipes.value = res.data.recipes
      if (res.data.recipes.length === 0 && type.value == GetRecipeType.SHARED_WITH_ME) {
        fwdToVetWelcome()
      }
      testRecipes.value = [res.data.recipes[0]]
      searching.value = false
    } catch (err) {
      errors.value = err.response.data.errors
      searching.value = false
    }
  }

  const getPets = async () => {
    try {
      let res = await PetService.listPets()
      pets.value = res.data.pets
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const destroyRecipe = async (recipe) => {
    Swal.fire({
      title: 'Are you sure?',
      text: recipe.name + "will be permanently deleted.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F28705',
      cancelButtonColor: '#618C03',
      confirmButtonText: 'Yes, delete it!'
    }).then( async (result) => {
      if (result.isConfirmed) {
        try {
          await RecipeService.destroyRecipe(recipe.id)
          getRecipes()
        } catch (err) {
          console.log(err);
          errors.value = err.response.data.errors
        }
      }
    })
  }

  const goToRecipe = (recipe) => {
    router.push({ name: 'RecipeNutrition', params: { id: recipe.id } })
  }

  const goToShowRecipe = (recipe) => {
    router.push({ name: 'ShowRecipe', params: { id: recipe.id } })
  }

  const editRecipe = (recipe) => {
    router.push({ name: 'EditRecipe', params: { id: recipe.id } })
  }

  const goToAddRecipe = () => {
    router.push({ name: 'CreateRecipe' })
  }

  const fwdToVetWelcome = () => {
    router.replace({ name: 'VeterinarianFormulatedRecipes' })
  }

  const canManageRecipe = (recipe) => {
    return recipe.user_id === userStore.id || userStore.isAdmin
  }

  const prepareToDuplicateRecipe = async (recipe) => {
    if (type.value == GetRecipeType.SHARED_WITH_ME) {
      showWarningModal.value = true
      proceedWithDuplication.value = () => {
        showWarningModal.value = false
        duplicateRecipe(recipe)
      }
      return
    }
    duplicateRecipe(recipe)
  }

  const duplicateRecipe = (recipe) => {
    Swal.fire({
      title: 'New Recipe Name',
      text: 'This recipe will be duplicated and saved in My Customized Recipes with the new name so that you may edit it now and any time.',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      confirmButtonColor: '#F28705',
      showLoaderOnConfirm: true,
      preConfirm: (name) => {
        return RecipeService.duplicateRecipe(recipe.id, name)
          .then(response => {
            if (!response.status == 201) {
              throw new Error(response.message)
            }
            return response.data
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Error: ${error.response.data.message}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        editRecipe(result.value.recipe)
      }
    })
  }

  const petImgUrl = (pet) => {
    if (pet.image == null) return '/img/profile_dog_sample.jpg'
    else return pet.image.url
  }

  const recipePetsBtnId = (recipe) => {
    return 'pets-btn-' + recipe.id
  }

  const recipeAdvancedEditWithPetBtnId = (recipe) => {
    return 'advanced-edit-with-pet-btn-' + recipe.id
  }

  const recipePetsDropdownId = (recipe) => {
    return 'pets-dropdown-' + recipe.id
  }

  const recipeAdvancedEditWithPetDropdownId = (recipe) => {
    return 'advanced-edit-with-pet-dropdown-' + recipe.id
  }

  const recipePetDropdownKey = (recipe, pet) => {
    return recipe.id + '-' + pet.id
  }

  const recipeAdvancedEditWithPetDropdownKey = (recipe, pet) => {
    return 'advanced-edit-with-pet-' + recipe.id + '-' + pet.id
  }

  const recipeActionsBtnId = (recipe) => {
    return 'actions-btn-' + recipe.id
  }

  const recipeActionsDropdownId = (recipe) => {
    return 'actions-dropdown-' + recipe.id
  }

  const goToVets = () => {
    router.push({ name: 'VeterinarianFormulatedRecipes' })
  }

  const goBack = () => {
    router.go(-1)
  }
</script>
