import Api from '../apis/api'

class SpecieService {
  listSpecies () {
    return Api.get('api/species')
  }

  getSpecie (specieSlug) {
    return Api.get('api/species/' + specieSlug)
  }
}

export default new SpecieService()
