<template>
    <ListRecipes v-if="!loading" :specie="specie" type="my-recipes"/>
</template>

<script setup>
  import ListRecipes from './ListSpecieRecipes.vue';
  import { onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import SpecieService from '../../services/specie.service'
  import { useToast } from 'vue-toastification'

  const route = useRoute()
  const toast = useToast()

  let specieSlug = ref('')
  let loading = ref(true)
  let specie = ref(null)

  onMounted(async () => {
    init()
  })

  const init = async () => {
    specieSlug.value = route.params.slug
    await getSpecie()
    loading.value = false
  }

  const getSpecie = async () => {
    try {
      let res = await SpecieService.getSpecie(specieSlug.value)
      specie.value = res.data.specie
    } catch (err) {
      toast.error('Failed to get specie')
    }
  }
</script>