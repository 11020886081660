<template>
  <div id="Register">
    <TopNavigation />

    <div class="flex-1 h-full max-w-4xl mx-auto bg-white rounded-lg shadow-xl mt-20 mb-20">
      <div class="flex flex-col md:flex-row">
        <div class="h-32 md:h-auto md:w-1/2">
          <img class="object-cover w-full h-full" src="@/assets/dog_carrot.jpg"
            alt="ChefPaw with Carrot" />
        </div>
        <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
          <div class="w-full">
            <h1 class="mb-4 text-2xl font-bold text-center text-gray-700">
              Reset Password
            </h1>
            <div class="mt-4">
              <TextInput 
                  label="Email"
                  placeholder="sam.doe@m.com"
                  v-model:input="email"
                  inputType="text"
                  :error="errors.email ? errors.email[0] : ''"
                  @keyup.enter="login"
              />
            </div>
            <div class="pt-4">
              <TextInput 
                  label="Password"
                  placeholder="password123?"
                  v-model:input="password"
                  inputType="password"
                  :error="errors.password ? errors.password[0] : ''"
                  @keyup.enter="submit"
              />
            </div>
            <div class="pt-4">
              <TextInput 
                  label="Password Confirmation"
                  placeholder="password123?"
                  v-model:input="passwordConfirmation"
                  inputType="password"
                  :error="errors.password_confirmation ? errors.password_confirmation[0] : ''"
                  @keyup.enter="submit"
              />
            </div>
            <button class="block w-full mt-6 px-4 py-3 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-innoorange border border-transparent rounded-lg active:bg-innoorange hover:bg-innoorange-hover focus:outline-none focus:shadow-outline-innoorange"
              type="submit"
              @click="submit" :disabled="isLoading">
              <svg v-if="isLoading" class="w-5 h-5 animate-spin ml-auto mr-auto" fill="none"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor"></path>
              </svg>
              <span v-if="!isLoading">Submit</span>
            </button>
            <div class="mt-4 text-center">
              <p class="text-sm">
                <router-link to="login" class="text-blue-600 no-underline hover:underline">
                  Back to Login
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import UserService from '../services/user.service'
  import TextInput from '../components/global/TextInput.vue'
  import TopNavigation from '../components/structure/TopNavigation.vue'
  import Swal from 'sweetalert2'
  import { useToast } from 'vue-toastification'

  const router = useRouter()
  let route = useRoute()
  const toast = useToast()

  let errors = ref([])
  let token = ref(null)
  let email = ref(null)
  let password = ref(null)
  let passwordConfirmation = ref(null)
  let isLoading = ref(false)

  onMounted(async () => {
    init()
  })

  const init = () => {
    if (route.query.token === undefined) {
      Swal.fire({
        title: 'Error',
        text: 'Sorry, the URL was found to not be a valid password reset URL. You will be redirected to the login page.',
        icon: 'error',
        confirmButtonColor: '#F28705'
      }).then(() => {
        router.push({ name: 'login' })
      })
    }
    token.value = route.query.token
  }

  const submit = async () => {
    errors.value = []

    try {
      isLoading.value = true
      await UserService.resetPassword(token.value, email.value, password.value, passwordConfirmation.value)
      isLoading.value = false
      toast.success("Your password was successfully reset. Please login.")
      router.push({ name: 'login' })
    } catch (err) {
      isLoading.value = false
      if (err.response.data.errors) errors.value = err.response.data.errors
      Swal.fire({
        title: 'Error',
        text: err.response.data.message,
        icon: 'error',
        confirmButtonColor: '#F28705'
      })
    }
  }
</script>
