<template>
    <div id="BatchRecipesDetail" class="container max-w-4xl mx-auto pt-10 pb-20 px6">
        
        <div class="flex flex-wrap justify-between mt-4 mb-6 mx-4 md:mx-0">
            <div class="basis-1/4">
                <button class="
                block
                w-full
                bg-innoorange
                hover:bg-innoorange-hover
                text-white
                rounded-sm
                py-3
                text-sm
                tracking-wide  
                "
                type="submit"
                @click="goBack()">
                Back
                </button>
            </div>
            <div class="w-1/4 ml-auto">
                <button class="
                block
                w-full
                bg-innoorange
                hover:bg-innoorange-hover
                text-white
                rounded-sm
                py-3
                text-sm
                tracking-wide  
                "
                type="submit"
                @click="goToStep1()">
                Recipe Instructions
                </button>
            </div>
        </div>
        
        <div class="text-gray-900 text-3xl text-center">Batch Summary:<br>{{ recipe.name }}</div>
        
        <div class="container max-w-4xl mx-auto">
            <div class="mb-4" >
                <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[700px]">
                    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                            <tr>
                                <th class="text-center	px-3 py-6">
                                    Steps
                                </th>
                                <th class="text-center	px-3 py-6">
                                    Ingredients
                                </th>
                                <th class="text-center	px-3 py-6">
                                    Grams
                                </th>
                            </tr>
                        </thead>
                        <tbody class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr v-for="step in recipe.recipe_steps" :key="step.id">
                                <td class="text-center px-3 py-3">{{ step.order }}</td>
                                <td class="text-center px-3 py-3">
                                    <router-link :to="{ name: 'RecipeSteps', params: { id: recipe.id, step: step.order } }">
                                        {{ step.basic_instructions }}
                                    </router-link>
                                </td>
                                <td class="text-center px-3 py-3">{{ step.ingredient !== null ? Math.round(step.ingredient.recipes[0].amount.total_g) : 'N/A' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="container max-w-4xl mx-auto text-xs text-gray-700">
            <div class="text-center"><strong>Warnings:</strong></div>
            <ol class="pl-5 mt-2 space-y-2 list-decimal list-inside font-bold text-left">
                <li>
                    <span class="font-normal">
                    Cooking dog food is not an exact science, it's really a mixture of science &amp; art.
                    Units in the ChefPaw app are measured in precise grams, however when it comes to cooking,
                    precision is very difficult, so it's important to remember that there is some margin for error.
                    It's totally fine if you accidentally add a little too much or are a little short of 1 ingredient.
                    However, a little too much of all the ingredients could lead to overfilling. <u>It is critical to not overfill the unit.</u>
                    </span>
                </li>
                <li>
                    <span class="font-normal">
                    The Chefpaw model CP3A should not be filled above the 3.0 Liter max. Overfilling can result in
                    parts of a batch being undercooked or excessive pressure building up. If the unit is overfilled
                    and hot, assume that excess pressure is under the lid, so do not attempt to open the lid until
                    the unit is unplugged and the mixing bowl has cooled down to room temperature. Excessive pressure
                    or improper use can lead to severe injury.
                    </span>
                </li>
                <li>
                    <span class="font-normal">
                    ChefPaw is NOT a toy and should not be left unattended especially while in use and it should never
                    be touched by children or pets. Chefpaw has moving sharp metal parts and Chefpaw food recipes will
                    look and smell attractive to pets and children, therefore extra caution must be used to prevent pets
                    or children from coming into contact with the Chefpaw machine especially while it is in operation.
                    </span>
                </li>
                <li>
                    <span class="font-normal">Never let a pet eat directly out of the Chefpaw mixing bowl due to the sharp edges.</span>
                </li>
                <li>
                    <span class="font-normal">
                    ALL food must be allowed to cool to room temperature BEFORE feeding it to your pet. If given the opportunity,
                    Pets will often eat food that is too hot, which can cause serious burns and injury.
                    </span>
                </li>
            </ol>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import RecipeService from '../../services/recipe.service'

  const route = useRoute()
  const router = useRouter()
  let recipe = ref({name: '...', ingredients: [], recipe_steps: []})

  onMounted(async () => {
      await getRecipe()
  })

  const getRecipe = async() => {
    let res = await RecipeService.getRecipeWithRecipeSteps(route.params.id)
    recipe.value = res.data.recipe
  }

  const goToStep1 = () => {
    router.push({ name: 'RecipeSteps', params: { id: recipe.value.id, step: 1 } })
  }

  const goBack = () => {
    router.go(-1)
  }

</script>

<style lang="scss" scoped></style>
