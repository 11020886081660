<template>
  <div id="AdminShowDietaryNeedsPerKg" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="text-gray-900 text-3xl text-center">{{ dietaryNeed.name }}<br>Dietary Recommendations (per kg body weight per day)</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div v-if="dietaryNeed.nutrition_breakdown.nutrients.length > 0" class="pt-5 pb-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Nutrient
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Amount
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Unit
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Serving Size
                      </th>
                      <td class="px-6 py-4">
                        {{ dietaryNeed.nutrition_breakdown.total_qty }}
                      </td>
                      <td class="px-6 py-4">
                        g
                      </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Energy (adult 1-10 lbs)
                      </th>
                      <td class="px-6 py-4">
                        {{ dietaryNeed.energy_1to10lbs_kcal }}
                      </td>
                      <td class="px-6 py-4">
                        kcal
                      </td>
                  </tr>
                  <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Energy (adult 11-33 lbs)
                      </th>
                      <td class="px-6 py-4">
                        {{ dietaryNeed.energy_11to33lbs_kcal }}
                      </td>
                      <td class="px-6 py-4">
                        kcal
                      </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Energy (adult 34-49 lbs)
                      </th>
                      <td class="px-6 py-4">
                        {{ dietaryNeed.energy_34to49lbs_kcal }}
                      </td>
                      <td class="px-6 py-4">
                        kcal
                      </td>
                  </tr>
                  <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Energy (adult 50-69 lbs)
                      </th>
                      <td class="px-6 py-4">
                        {{ dietaryNeed.energy_50to69lbs_kcal }}
                      </td>
                      <td class="px-6 py-4">
                        kcal
                      </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Energy (adult 70+ lbs)
                      </th>
                      <td class="px-6 py-4">
                        {{ dietaryNeed.energy_70pluslbs_kcal }}
                      </td>
                      <td class="px-6 py-4">
                        kcal
                      </td>
                  </tr>
                  <tr v-for="(nutrient, index) in dietaryNeed.nutrition_breakdown.nutrients" :key="nutrient.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ nutrient.name }}
                      </th>
                      <td class="px-6 py-4">
                        {{ nutrient.amount.qty }}
                      </td>
                      <td class="px-6 py-4">
                        {{ nutrient.amount.unit_type }}
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>


  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import DietaryNeedService from '@/services/dietaryNeed.service.js'

  const route = useRoute()

  let dietaryNeed = ref({name: '...', nutrition_breakdown: {nutrients: []}})
  let errors = ref([])
  let loading = ref(true)

  onMounted(async () => {
      await getDietaryNeed()
  })

  const getDietaryNeed = async () => {
    try {
      loading.value = true
      let res = await DietaryNeedService.getDietaryNeed(route.params.id)
      dietaryNeed.value = res.data.dietary_per_kg_need
      loading.value = false
      console.log(res.data)
    } catch (err) {
      errors.value = err.response.data.errors
      loading.value = false
    }
  }
</script>
