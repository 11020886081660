import Api from '../apis/api'

class TagService {
  listSpecieRecipeTags (specieSlug) {
    return Api.get('api/species/' + specieSlug + '/recipe-tags')
  }

  listSpecieRecipeTagsAndOmitTagsWithParent (specieSlug) {
    return Api.get('api/species/' + specieSlug + '/recipe-tags?omit_tags_with_parent=1')
  }

  getSpecieRecipeTag (specieSlug, tagSlug) {
    return Api.get('api/species/' + specieSlug + '/recipe-tags/' + tagSlug)
  }
}

export default new TagService()
