import Api from '../apis/api'

class IngredientService {
  searchIngredientsByName (query) {
    return Api.get('api/ingredients?query=' + query)
  }

  getIngredient (id) {
    return Api.get('api/ingredients/' + id)
  }

  getIngredientWithPrices (id) {
    return Api.get('api/ingredients/' + id, { params: { include_prices: true } })
  }

  listIngredients () {
    return Api.get('api/ingredients')
  }

  listIngredientsWithPrices () {
    return Api.get('api/ingredients', { params: { include_prices: true } })
  }

  adminUpdateIngredient (ingredientId, data) {
    return Api.post('api/admin/ingredients/' + ingredientId + '?_method=PUT', data)
  }

  adminCreateIngredient (name) {
    return Api.post('api/admin/ingredients', { name: name })
  }

  adminDeleteIngredient (ingredientId) {
    return Api.delete('api/admin/ingredients/' + ingredientId)
  }

  adminCreateNote (ingredientId, msg) {
    return Api.post('api/admin/ingredients/' + ingredientId + '/notes', { msg: msg })
  }

  adminDeleteNote (ingredientId, noteId) {
    return Api.delete('api/admin/ingredients/' + ingredientId + '/notes/' + noteId)
  }
}

export default new IngredientService()
