import Api from '../apis/api'

class DietaryNeedService {
  listDietaryNeeds () {
    return Api.get('api/dietary-per-kg-needs')
  }

  listDietaryNeedsWithAcitivityLevelsAndLifePhase () {
    return Api.get('api/dietary-per-kg-needs?include_activity_levels=true&include_life_phase=true')
  }

  getDietaryNeedWithAcitivityLevelsAndLifePhase (id) {
    return Api.get('api/dietary-per-kg-needs/' + id + '?include_activity_levels=true&include_life_phase=true')
  }

  getDietaryNeed (id) {
    return Api.get('api/dietary-per-kg-needs/' + id)
  }

  getBlankDietaryNeed () {
    return Api.get('api/blank-dietary-per-kg-needs')
  }

  updateDietaryNeed (id, data) {
    return Api.post('api/dietary-per-kg-needs/' + id + '?_method=PUT', data)
  }

  createDietaryNeed (data) {
    return Api.post('api/dietary-per-kg-needs', data)
  }

  deleteDietaryNeed (id) {
    return Api.delete('api/dietary-per-kg-needs/' + id)
  }

  duplicateDietaryNeed (id, name) {
    return Api.post('api/duplicate-dietary-per-kg-needs/' + id, { name: name })
  }
}

export default new DietaryNeedService()
