<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="flex flex-wrap mt-4 mb-6 mx-4 md:mx-4">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">{{ pet.name }} Daily Nutrition Targets<br>{{ petGramsPerDayRounded }} grams (approx. {{ petCupsPerDayText }}) per day, divided into 2-3 meals</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div v-if="pet.daily_nutrition_breakdown != null" class="pt-5 pb-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[700px]">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                  <tr>
                      <th scope="col" class="px-6 py-6">
                          Nutrient
                      </th>
                      <th scope="col" class="px-6 py-6">
                          Quantity
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(nutrient, index) in pet.daily_nutrition_breakdown.nutrients" :key="nutrient.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ nutrient.name }}
                      </th>
                      <td class="px-6 py-4">
                        {{ nutrient.amount.qty }}{{ nutrient.amount.unit_type }}
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>


  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import PetService from '../../services/pet.service'

  const route = useRoute()
  const router = useRouter()

  let pet = ref({name: '', daily_nutrition_breakdown: null})
  let petGramsPerDay = ref('')
  let errors = ref([])

  onMounted(async () => {
      await getPet()
  })

  const getPet = async () => {
    console.log('getting pet');
    try {
      let res = await PetService.getPetWithDailyNutrition(route.params.id)
      pet.value = res.data.pet
      petGramsPerDay.value = pet.value.daily_nutrition_breakdown.total_qty
      console.log(res.data)
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const petGramsPerDayRounded = computed({
      get: () => Math.round(petGramsPerDay.value)
  })

  const petCupsPerDayRounded = computed({
      get: () => Math.round(petGramsPerDay.value / 226)
  })

  const petCupsPerDayText = computed({
      get: () => petCupsPerDayRounded.value == 1 ? petCupsPerDayRounded.value + ' cup' : petCupsPerDayRounded.value + ' cups'
  })

  const goBack = () => {
    router.go(-1)
  }
</script>
