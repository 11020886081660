import Api from '../apis/api'

class MealPlanService {
  createMealPlan (data) {
    return Api.post('api/meal-plans', data)
  }

  getMealPlan (id) {
    return Api.get('api/meal-plans/' + id)
  }

  getMealPlanWithIngredients (id) {
    return Api.get('api/meal-plans/' + id + '?include_ingredients=true')
  }

  getMealPlanWithNutrition (id) {
    return Api.get('api/meal-plans/' + id + '?include_nutrition_breakdown=true')
  }

  getMealPlanWithNutritionAndRecipeNutrition (id) {
    return Api.get('api/meal-plans/' + id + '?include_nutrition_breakdown=true&include_recipes_nutrition_breakdown=true')
  }

  listMealPlans () {
    return Api.get('api/meal-plans')
  }

  addRecipes (id, recipes) {
    return Api.post('api/meal-plans/' + id + '/add-recipes', recipes)
  }

  updateMealPlan (id, data) {
    return Api.post('api/meal-plans/' + id + '?_method=PUT', data)
  }

  deleteMealPlan (id) {
    return Api.delete('api/meal-plans/' + id)
  }
}

export default new MealPlanService()
