import Api from '../apis/api'

class CaPRatioService {
  getDefault () {
    return Api.get(`api/default-ca-p-ratio`)
  }

  update (id, low_max, high_min) {
    return Api.post('api/ca-p-ratios/' + id + '?_method=PUT', {
      low_max: low_max,
      high_min: high_min
    })
  }
}

export default new CaPRatioService()
