import axios from 'axios'
import { useUserStore } from '../store/user-store'

// let baseUrl = process.env.VUE_APP_API_BASE_URL == null ? 'https://api-staging.boxigo.com' : process.env.VUE_APP_API_BASE_URL
let baseUrl = process.env.VUE_APP_API_URL

let Api = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {
    common: {
      Accept: 'application/json'
    }
  }
})

// Intercept requests to add bearer token if present in user store
Api.interceptors.request.use(
  async config => {
    const userStore = useUserStore()
    if (userStore.token != null) {
      config.headers = { 
        'Authorization': `Bearer ${userStore.token}`,
        'Accept': 'application/json'
      }
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

export default Api
