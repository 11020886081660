<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="text-gray-900 text-3xl text-center">Add Meal Plan</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Meal Plan Name"
            placeholder="Spot's Balanced Diet"
            v-model:input="planName"
            inputType="text"
            :error="errors.name ? errors.name[0] : ''"
        />
      </div>
    </div>

    <div class="flex flex-wrap mt-8 mb-6">
      <div class="w-full px-3">
        <SubmitFormButton 
            btnText="Create Meal Plan"
            @click="createPlan"
        />
      </div>
    </div>

  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import TextInput from '@/components/global/TextInput.vue'
  import SubmitFormButton from '@/components/global/SubmitFormButton.vue'
  import MealPlanService from '@/services/mealPlan.service.js'

  const router = useRouter()

  let planName = ref(null)
  let errors = ref([])

  const createPlan = async () => {
    errors.value = []

    let data = new FormData();
    data.append('name', planName.value || '')

    try {
      let res = await MealPlanService.createMealPlan(data)
      router.push({ name: 'EditMealPlan', params: { id: res.data.meal_plan.id } })
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }
</script>
