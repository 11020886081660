import Api from '../apis/api'

class PetService {
  listPets () {
    return Api.get('api/pets')
  }

  listSpeciePets (specie) {
    return Api.get('api/pets?specie=' + specie)
  }
  
  addPet (data) {
    return Api.post('api/pets', data)
  }

  updatePet (id, data) {
    return Api.post('api/pets/' + id + '?_method=PUT', data)
  }

  getPet (id) {
    return Api.get('api/pets/' + id)
  }

  getPetWithDailyNutrition (id) {
    return Api.get('api/pets/' + id + '?include_daily_nutrition_breakdown=true')
  }

  getPetWithDailyNutritionAndDietaryPerKgNeed (id) {
    return Api.get('api/pets/' + id + '?include_daily_nutrition_breakdown=true&include_dietary_per_kg_need=true')
  }

  deletePet (id) {
    return Api.delete('api/pets/' + id)
  }
}

export default new PetService()
