import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import './styles/app.css'; // Here

import UserService from './services/user.service.js'
import { useUserStore } from './store/user-store.js'

import Toast from "vue-toastification"
import "./styles/vue-toastification.css"

import Vue3Sanitize from "vue-3-sanitize"

startApp()

async function startApp () {
  const pinia = createPinia()
  pinia.use(piniaPluginPersistedstate)

  const app = createApp(App)

  const toastOptions = {
    // You can set your default vue-toastification options here
  }

  app.use(router)
  app.use(pinia)
  app.use(Toast, toastOptions)
  app.use(Vue3Sanitize)

  app.config.globalProperties.$filters = {
    strLimit(value, size) {
      if (!value) return ''
      value = value.toString()
  
      if (value.length <= size) {
        return value
      }
      return value.substr(0, size).trim() + '...'
    },
    posessiveNoun(value) {
      if (value && value.length > 2) {
        if (value.slice(-1) == 's') return value + "'"
        else return value + "'s"
      }
      return ''
    }
  }

  // This callback runs before every route change, including on page load.
  // reference: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
  router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
      document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

    next();
  })

  const userStore = useUserStore()

  // attempt to auto refresh token before startup
  try {
    let res = await UserService.getMe()
    userStore.setUserDetails(res)
  } catch {
    userStore.clearUser()
  }

  app.mount('#app')
}
