import Api from '../apis/api'

class RecipeStepService {
  nextStep (recipeId, currentStep) {
    return Api.get(`api/recipe-steps/navigate/${recipeId}/${currentStep}/next`)
  }

  previousStep (recipeId, currentStep) {
    return Api.get(`api/recipe-steps/navigate/${recipeId}/${currentStep}/previous`)
  }
}

export default new RecipeStepService()
