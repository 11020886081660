<template>
  <div id="ShowRecipeGroceryList" class="container max-w-4xl mx-auto pt-10 pb-20 px6">

    <div class="flex justify-between flex-wrap mt-4 mb-6 mx-4 md:mx-0">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide
        "
          type="submit"
          @click="downloadGroceryList()">
          Download
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">
      Grocery List -
      <select class="
              appearance-none
              inline-block
              w-20
              bg-white
              text-gray-700
              border
              border-gray-400
              rounded
              py-3
              px-4
              leading-tight
              focus:outline-none
              focus:bg-white
              focus:border-gray-500
              "
              v-model="qtyBatches"
      >
        <option v-for="qty in qtyBatchesOptions" :key="qty" :selected="qty == qtyBatches" :value="qty">{{ qty }}</option>
      </select>
      {{ batchOrBatches }}
    </div>
    <div class="bg-innogreen w-full h-1 mt-2"></div>
    <div class="text-gray-900 text-xl text-center">{{ recipe.name }}</div>

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full">
        {{ recipe.description }}
      </div>
    </div>

    <div v-if="recipe.ingredients.length > 0" class="pt-2 pb-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[700px]">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                  <tr>
                    <th scope="col" class="pl-4 pr-2 py-6">
                      <i class="fa-solid fa-check fa-xl text-white"></i>
                    </th>  
                    <th scope="col" class="px-2 py-6">
                      Ingredient
                    </th>
                    <th scope="col" class="px-2 py-6">
                      Grams
                    </th>
                    <th scope="col" class="px-2 py-6">
                      Lbs
                    </th>
                    <th scope="col" class="pl-2  pr-4 py-6">
                      Oz
                    </th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <td class="pl-4 pr-2 py-4">
                        &nbsp;
                    </td>  
                    <th scope="row" class="md:hidden px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <Popper :content="ingredientNameFullBatch" :disabled="ingredientNameFullBatch.length < ingredientMaxChars">
                        {{ $filters.strLimit(ingredientNameFullBatch, ingredientMaxChars) }}
                      </Popper>
                    </th>
                    <th scope="row" class="hidden md:table-cell px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {{ ingredientNameFullBatch }}
                    </th>
                    <td class="px-2 py-4">
                      {{ Math.ceil(totalDisplay * qtyBatches) }}
                    </td>
                    <td class="px-2 py-3">
                      {{ gToLbs(recipe.nutrition_breakdown.total_qty * qtyBatches) }}
                    </td>
                    <td class="pl-2 pr-4 py-3">
                      {{ gToOz(recipe.nutrition_breakdown.total_qty * qtyBatches) }}
                    </td>
                  </tr>
                  <tr v-for="(ingredient, index) in recipe.ingredients" :key="recipeIngredientKey(recipe, ingredient)"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <td class="pl-4 pr-2 py-4">
                        <input type="checkbox" :id="ingredient.id" v-model="ingredient.checked" class="p-2">
                      </td>  
                      <th scope="row" class="md:hidden break-words px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <Popper :content="ingredient.name" :disabled="ingredient.name.length < ingredientMaxChars">
                          {{ $filters.strLimit(ingredient.name, ingredientMaxChars) }}
                        </Popper>
                      </th>
                      <th scope="row" class="hidden md:table-cell break-words px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ ingredient.name }}
                      </th>
                      <td class="px-2 py-4">
                        {{ Math.ceil(ingredient.amount.total_g * qtyBatches) }}
                      </td>
                      <td class="px-2 py-3">
                        {{ gToLbs(ingredient.amount.total_g * qtyBatches) }}
                      </td>
                      <td class="pl-2 pr-4 py-3">
                        {{ gToOz(ingredient.amount.total_g * qtyBatches) }}
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import RecipeService from '../../services/recipe.service'
  import Popper from "vue3-popper"
  import { jsPDF } from "jspdf"
  import _ from 'lodash'

  const route = useRoute()
  const router = useRouter()

  let recipe = ref({name: '...', description: '...', ingredients: []})
  let errors = ref([])
  let ingredientMaxChars = ref(14)
  let qtyBatchesOptions = ref([1, 2, 3, 4, 5])
  let qtyBatches = ref(1)

  onMounted(async () => {
      await getRecipe()
  })

  const downloadGroceryList = () => {
    const doc = new jsPDF()

    console.log(doc.getFont())
    console.log(doc.getFontList())

    doc.setFont('helvetica', 'normal', 'normal')

    const lGrams = 20
    const lLbs = 40
    const lOz = 56
    const lIngredient = 73
    // const barQtyBefore = 2
    const ingredientYspace = 8

    doc.setFontSize(20)
    doc.text("Grocery List for " + qtyBatches.value + " " + batchOrBatches.value, 10, 10)
    doc.setFontSize(16)
    doc.text(recipe.value.name, 10, 20)
    doc.setFontSize(12)
    doc.setFont('helvetica', 'normal', 'bold')
    doc.text('GRAMS', lGrams, 30)
    // doc.text('|', (lLbs - barQtyBefore), 30)
    doc.text('LBS', lLbs, 30)
    // doc.text('|', (lOz - barQtyBefore), 30)
    doc.text('OZ', lOz, 30)
    // doc.text('|', (lIngredient - barQtyBefore), 30)
    doc.text('INGREDIENT', lIngredient, 30)
    doc.setFont('helvetica', 'normal', 'normal')

    let nextY = 37
    recipe.value.ingredients.forEach(i => {
      doc.rect(10, nextY, 3, -3)
      doc.text(Math.ceil(i.amount.total_g * qtyBatches.value).toString(), lGrams, nextY)
      // doc.text('|', (lLbs - barQtyBefore), nextY)
      doc.text(gToLbs(i.amount.total_g * qtyBatches.value).toString(), lLbs, nextY)
      // doc.text('|', (lOz - barQtyBefore), nextY)
      doc.text(gToOz(i.amount.total_g * qtyBatches.value).toString(), lOz, nextY)
      // doc.text('|', (lIngredient - barQtyBefore), nextY)
      doc.text(i.name, lIngredient, nextY)
      nextY += ingredientYspace
    });

    var filename = recipe.value.name.replace(/[^a-zA-Z0-9 ]/g, '')
    filename = filename.replace(/\s+/g, '_') + '.pdf'
    doc.save(filename)
  }

  const getRecipe = async () => {
    try {
      let res = await RecipeService.getRecipeWithNutrition(route.params.id)
      recipe.value = res.data.recipe
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const recipeIngredientKey = (recipe, ingredient)  => {
    return recipe.id+'-'+ingredient.id
  }

  const gToLbs = (g) => {
    let lbs = g / 453.592
    let lbsCeil = Math.ceil(lbs * 100) / 100
    return lbsCeil.toFixed(2)
  }

  const gToOz = (g) => {
    let oz = g / 28.3495
    let ozCeil = Math.ceil(oz * 100) / 100
    return ozCeil.toFixed(2)
  }

  const sumIngredientsGrams = () => {
    let sum = 0
    _.each(recipe.value.ingredients, function (ingredient) {
      sum += parseFloat(ingredient.amount.total_g)
    })
    return sum
  }

  const recipeIngredientsGramsDeviation = () => {
    return recipe.value.nutrition_breakdown.total_qty - sumIngredientsGrams()
  }

  const getTotalGramsText = () => {
    let deviation = recipeIngredientsGramsDeviation()
    if (deviation > 0) return sumIngredientsGrams() + " (" + deviation + " under " + recipe.value.nutrition_breakdown.total_qty + ")"
    else if (deviation < 0) return sumIngredientsGrams() + " (" + (deviation * -1) + " over " + recipe.value.nutrition_breakdown.total_qty + ")"
    else return recipe.value.nutrition_breakdown.total_qty
  }

  const goBack = () => {
    router.go(-1)
  }

  const ingredientNameFullBatch = computed({
      get: () => {
          // let str = 'Total batch size ' + recipe.value.nutrition_breakdown.total_qty + ' x ' + qtyBatches.value + ' is ' + (recipe.value.nutrition_breakdown.total_qty * qtyBatches.value) + ' grams'
          let str = 'Total'
          return str
      }
  })

  const batchOrBatches = computed({
    get: () => {
      if (qtyBatches.value > 1) return 'Batches'
      else return 'Batch'
    }
  })

  const totalDisplay = computed({
      get: () => getTotalGramsText()
  })
</script>
