<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="flex flex-wrap justify-between mt-4 mb-6 mx-4 md:mx-0">
      <div class="basis-1/4">
          <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
          "
          type="submit"
          @click="goBack()">
          Back
          </button>
      </div>
      <div class="w-1/4 ml-auto">
          <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
          "
          type="submit"
          @click="updateRstg()" :disabled="saving">
            <svg v-if="saving" class="w-5 h-5 animate-spin ml-auto mr-auto" fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    fill="currentColor"></path>
            </svg>
            <span v-if="!saving">Save</span>
          </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">{{ rstg.name }}</div>

    <div v-for="template in rstg.recipe_step_templates" :key="template.id">
      <div class="bg-innogreen w-full h-1 my-2"></div>
      <div class="text-gray-900 text-2xl text-left">{{ template.sort_order }} - {{ template.type }}</div>
      <div class="text-gray-900 text-xl text-left">{{ templateTypeDescription(template.type) }}</div>
      <div class="flex flex-wrap mt-4 mb-6">
        <div class="w-full">
          <TextArea 
              label="Basic Instructions"
              placeholder="Overview of this step"
              v-model:description="template.basic_instructions"
          />
        </div>
      </div>
      <div v-if="template.type !== 'regular_ingredients' && template.type !== 'heat_sensitive_ingredients'" class="flex flex-wrap mt-4 mb-6">
        <div class="w-full">
          <TextArea 
              label="Detailed Instructions"
              placeholder="Complete instructions for this step"
              v-model:description="template.detailed_instructions"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import RecipeStepTemplateGroupService from '../../services/recipeStepTemplateGroup.service'
  import TextArea from '@/components/global/TextArea.vue'
  import { useToast } from "vue-toastification"
  // import Swal from 'sweetalert2'

  const route = useRoute()
  const router = useRouter()
  const toast = useToast()

  let rstg = ref({})
  let errors = ref([])
  let saving = ref(false)

  onMounted(async () => {
    getRecipeStepTemplateGroup()
  })

  const getRecipeStepTemplateGroup = async () => {
    console.log('getRecipeStepTemplateGroup', route.params.id)
    try {
      let res = await RecipeStepTemplateGroupService.getRecipeStepTemplateGroup(route.params.id)
      rstg.value = res.data.recipe_step_template_group
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const templateTypeDescription = (type) => {
    switch (type) {
      case 'standard':
        return 'Single step'
      case 'regular_ingredients':
        return 'Template that creates a step for each regular ingredient in the recipe'
      case 'heat_sensitive_ingredients':
        return 'Template that creates a step for each heat sensitive ingredient in the recipe'
      case 'speed1':
        return 'Single step for recipes with speed 1'
      case 'speed2':
        return 'Single step for recipes with speed 2'
      case 'recipe_has_heat_sensitive_ingredients':
        return 'Single step for recipes with heat sensitive ingredients'
      case 'recipe_does_not_have_heat_sensitive_ingredients':
        return 'Single step for recipes without heat sensitive ingredients'
    }
  }

  const updateRstg = async () => {
    console.log('updateRstg', rstg.value)
    let data = new FormData();
    data.append('recipe_step_template_group', JSON.stringify(rstg.value))

    try {
      console.log('data', data)
      saving.value = true
      let res = await RecipeStepTemplateGroupService.updateRecipeStepTemplateGroup(rstg.value.id, data)
      rstg.value = res.data.recipe_step_template_group
      toast.success(rstg.value.name + " saved.")
      saving.value = false
    } catch (err) {
      saving.value = false
      console.log('err', err)
      if (err.response.data.errors && Array.isArray(err.response.data.errors)) errors.value = err.response.data.errors
      toast.error(err.response.data.message)
    }
  }

  const goBack = () => {
    router.go(-1)
  }
</script>
