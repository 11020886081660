<template>
  <div v-if="userStore.isBecomeUser" class="announcement-bar" aria-label="Announcement">
    <a href="javascript:void(0)" @click="adminStopBeingUser()">Stop being {{ userStore.firstName }} {{ userStore.lastName }}</a>
  </div>
  <TopNavigation />
  <div id="AccountView" class="bg-innowhite">
    <router-view/>
  </div>
  <FooterSection />
</template>

<style lang="scss">
  #AccountView {
    min-height: 70vh;
  }
  .announcement-bar {
    background: #fee36e;
    background-attachment: fixed;
    border-bottom: 0.1rem solid rgba(18, 18, 18, 0.08);
    color: rgb(18, 18, 18);
    padding: 0.5rem 1rem;
    text-align: center;
  }
</style>

<script setup>
  // import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { useUserStore } from '../store/user-store'
  import TopNavigation from '../components/structure/TopNavigation.vue'
  import FooterSection from '../components/structure/FooterSection.vue'

  const router = useRouter()
  const userStore = useUserStore()

  // onMounted(async () => {
  //     await getIngredients()
  // })
  const adminStopBeingUser = async () => {
    await userStore.adminStopBeingUser()
    router.push('/account/home')
  }
</script>
