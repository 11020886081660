<template>
  <div id="ListUsers" class="container max-w-4xl mx-auto pt-20 pb-20 px6">

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">{{ user.first_name }}'s Latest Auth Attempts</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div v-if="!searching && authAttempts.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Login Date
                      </th>
                      <th scope="col" class="px-6 py-3">
                          First Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Last Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Email
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Successful?
                      </th>
                      <th scope="col" class="px-6 py-3">
                          IP Address
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(authAttempt, index) in authAttempts" :key="authAttempt.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ formatDate(authAttempt.created_at) }}
                      </th>
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ authAttempt.user.first_name }}
                      </th>
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ authAttempt.user.last_name }}
                      </th>
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ authAttempt.user.email }}
                      </th>
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ authAttempt.successful }}
                      </th>
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <a :href="linkToGeoIp(authAttempt.ip)" target="_blank" class="font-medium text-blue-600 dark:text-innogreen hover:underline">{{ authAttempt.ip }}</a>
                      </th>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import UserService from '../../services/user.service'
  import authAttemptService from '@/services/authAttempt.service'

  const router = useRouter()
  const route = useRoute()

  let searching = ref(false)
  let user = ref({first_name: '...'})
  let authAttempts = ref([])

  onMounted(async () => {
      // nothing
      getUser(route.params.id)
      getAuthAttempts(route.params.id)
  })

  const getUser = async (userId) => {
    try {
      let res = await UserService.adminGetUser(userId)
      user.value = res.data.user
    } catch (err) {
      console.log(err)
    }
  }

  const getAuthAttempts = async (userId) => {
    try {
      let res = await authAttemptService.getUserAuthAttempts(userId)
      authAttempts.value = res.data.auth_attempts
      console.log(res.data.auth_attempts)
    } catch (err) {
      console.log(err)
    }
  }

  const formatDate = (d) => {
    let dateStr = d
    const dateObj = new Date(dateStr);

    // Array of month names to get the month as a string
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const day = dateObj.getUTCDate();          // get day of the month
    const month = monthNames[dateObj.getUTCMonth()];  // get month name
    const year = dateObj.getUTCFullYear();     // get full year
    
    return `${month} ${day}, ${year}`;  // Construct the desired format
  }

  const linkToGeoIp = (ip) => {
    return 'https://whatismyipaddress.com/ip/' + ip
  }

  const goBack = () => {
    router.go(-1)
  }
</script>
