<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-10 pb-20 px6">

    <div class="flex flex-wrap mt-4 mb-6 mx-4 md:mx-0">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
    </div>
    
    <div class="text-gray-900 text-3xl text-center">Specialty Recipes</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div class="grid place-items-center">
      <div class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="goDogRecipesCategory('novel-protein-allergy-free')">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-dna w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Novel Protein + Allergy Free</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">Recipes that explore novel proteins like Bison or Duck. These recipes are also free of allergens.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="goDogRecipesCategory('allergy-free')">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-wheat-awn-circle-exclamation w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Allergy Free</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">Recipes suitable for dogs with allergies.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="goDogRecipesCategory('small-dog')">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-hotdog w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Small Dog</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">Recipes for active smaller dogs with higher calorie needs.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="goDogRecipesCategory('renal-other')">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-flask w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Renal &amp; Other</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">Recipes for dogs that need the general renal diet plan or that have other special needs.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import UserService from '@/services/user.service.js'
  import { useUserStore } from '@/store/user-store.js'

  const router = useRouter()
  const userStore = useUserStore()

  onMounted(async () => {
      await init()
  })

  const init = async () => {
    try {
      await UserService.getMe()
    } catch {
      await userStore.clearUser()
      router.push({ name: 'login' })
    }
  }

  const goDogRecipesCategory = (tagSlug) => {
    router.push({ name: 'ListDogrecipesCategory', params: { tagSlug: tagSlug } })
  }

  const goBack = () => {
    router.go(-1)
  }

</script>
