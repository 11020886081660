<template>
  <div id="RecipeInstruction" class="container max-w-4xl mx-auto pt-10 pb-20 px6">
    <div class="flex justify-center">
        <div class="justify-center">
            <h1 class="mb-4 text-[2rem] font-bold text-center text-gray-700">{{step.recipe_name}}</h1>
            <h2 class="mb-4 text-[1.5rem] font-bold text-center text-gray-700">3 Liter Batch</h2>
            <div class="text-center flex justify-center">
                <button
                    class="block px-40 bg-innoorange hover:bg-innoorange-hover text-white rounded-sm py-3 text-sm tracking-wide"
                    type="submit" @click="backToBatchSummaryPage"> Return to batch summary </button>
            </div>
            <div class="text-center text-[#1877F2] font-[600] text-[4rem]">STEP {{step.order}}:</div>
            <p class="text-center text-yellow-400 text-[2rem]">{{ step.basic_instructions }}</p>
            <p class="text-center text-[1.25rem] m-5">{{ step.detailed_instructions }}</p>
        </div>

    </div>
    <div class="flex justify-around pb-[1rem] mt-[1rem]">
        <button
            class="block bg-innoorange hover:bg-innoorange-hover text-white p-[30px] rounded-sm tracking-wide" @click="previous">Back</button>
        <button class="block bg-innoorange hover:bg-innoorange-hover text-white p-[30px] tracking-wide" @click="next">Next</button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import RecipeStepService from '../../services/recipeStep.service'

let searching = ref(false)
  let step = ref({})
  let errors = ref([])
  let route = useRoute();
  let router = useRouter()

  onMounted(async () => {
      await next()
  })

  const backToBatchSummaryPage = () => {
        router.push({ name: 'RecipesBatchSummary', params: { id: route.params.id } })
  }

  const next = async () => {
    searching.value = true
    try {
      let res = await RecipeStepService.nextStep(route.params.id, step.value.order || 0)
      step.value = res.data
    } catch (err) {
      errors.value = err.response.data.errors
    }
    searching.value = false
  }

  const previous = async () => {
    searching.value = true
    try {
      let res = await RecipeStepService.previousStep(route.params.id, step.value.order || 0)
      step.value = res.data
    } catch (err) {
      errors.value = err.response.data.errors
    }
    searching.value = false
  }
</script>

