<template>
  <div id="SubtagListForSpecieTag">
    
    <div class="text-gray-900 text-3xl text-center">Suggested Dog Recipes</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div class="grid place-items-center">
      <div v-for="subtag in tag.tags" :key="subtag.slug" class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="goSpecieRecipesCategory(subtag)">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid w-5 h-5" :class="subtag.icon_css_class"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">{{ subtag.name.en }}</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">{{ subtag.description }}</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { defineProps, toRefs, onMounted } from 'vue'
  import { useRouter } from 'vue-router'

  const router = useRouter()

  const props = defineProps({
    specie: Object,
    tag: Object
  })
  const { specie, tag } = toRefs(props)

  onMounted(async () => {
      await init()
  })

  const init = async () => {
    // nothing to do
  }

  const goSpecieRecipesCategory = (subtag) => {
    router.push({ name: 'SpecieRecipesCategory', params: {  slug: specie.value.slug, tag: subtag.slug.en } })
    
  }

</script>
